import { Box, Float, OrbitControls } from "@react-three/drei";
import { useEffect, useRef, useState } from "react";
import { Bear } from "./Bear";
import { BabyCar } from "./BabyCar";
import { Balloon1 } from "./Balloon1";
import { Balloon2 } from "./Balloon2";
import { Car } from "./Car";
import { SphereToy } from "./SphereToy";
import { Text } from "./Text";
import { Billboard } from "./Billboard";
import { Physics, RigidBody } from "@react-three/rapier";
import XR8Scene from "./XR8Scene";
import { Canvas } from "@react-three/fiber";

export default function Experience() {
  const canvasRef = useRef();
  const R3Scene = useRef();
  const appScene = useRef();
  const groupScene = useRef();
  const [loaded, setLoaded] = useState(false);
  const [imageTargetVisible, setImageTargetVisible] = useState(false);

  let { onxrloaded } = XR8Scene(
    canvasRef,
    R3Scene,
    setLoaded,
    appScene,
    groupScene,
    setImageTargetVisible
  );

  // useEffect(() => {
  //   document.querySelector(".js-text").classList.remove("hide");
  //   setImageTargetVisible(true);
  // }, []);

  useEffect(() => {
    if (loaded && !imageTargetVisible) {
      document.querySelector(".js-scan").classList.remove("hide");
    }

    if (!loaded) {
      XRExtras.Loading.showLoading({ onxrloaded });
    }
  }, [loaded]);

  useEffect(() => {
    if (imageTargetVisible) {
      document.querySelector(".js-text").classList.remove("hide");
      document.querySelector(".js-scan").classList.add("hide");
    }
  }, [imageTargetVisible]);

  return (
    <>
      <div className="App">
        <Canvas style={{ position: "absolute" }} shadows>
          <scene ref={R3Scene}>
            {/* <OrbitControls /> */}

            <group ref={appScene} visible={false}>
              <group ref={groupScene} scale={0.1}>
                <directionalLight
                  castShadow
                  position={[0, 6, 3]}
                  intensity={1}
                />
                <ambientLight intensity={0.5} />

                <Physics>
                  <RigidBody gravityScale={3} colliders="hull">
                    <Bear />
                  </RigidBody>

                  <BabyCar
                    rotation={[0, -Math.PI / 8, 0]}
                    position={[5, 0, 0]}
                  />

                  <Float
                    rotationIntensity={0.01}
                    speed={3}
                    rotation={[0, -Math.PI / 2, 0]}
                  >
                    <Balloon1 color="white" />
                  </Float>

                  <Float
                    rotationIntensity={0.04}
                    speed={3}
                    rotation={[0, -Math.PI / 2, 0]}
                  >
                    <Balloon1 color="black" position={[8, 0, -1]} />
                  </Float>

                  <Float
                    rotationIntensity={0.02}
                    speed={3}
                    rotation={[0, -Math.PI / 2, 0]}
                  >
                    <Balloon2 color="white" position={[0, 0, 4]} />
                  </Float>

                  <Float
                    rotationIntensity={0.02}
                    speed={3}
                    rotation={[0, -Math.PI / 2, 0]}
                  >
                    <Balloon2 color="black" position={[10, 0, 4]} />
                  </Float>

                  <Float
                    rotationIntensity={0.01}
                    speed={3}
                    rotation={[0, -Math.PI / 2, 0]}
                  >
                    <Balloon1 color="white" position={[10, 0, -10]} />
                  </Float>

                  <Float
                    rotationIntensity={0.02}
                    speed={3}
                    rotation={[0, -Math.PI / 2, 0]}
                  >
                    <Balloon2 color="white" position={[0, 3, -8]} />
                  </Float>

                  <Car position={[3, 0.5, 2]} />

                  <RigidBody colliders="hull" gravityScale={3}>
                    <SphereToy position={[5, 0, 4]} />
                  </RigidBody>

                  <RigidBody type="fixed" colliders="trimesh">
                    <Billboard position={[0, 0, -12]} />
                  </RigidBody>

                  <group position={[-3, 0, -6]}>
                    <RigidBody gravityScale={1}>
                      <Text
                        name="F"
                        scale={0.75}
                        position={[-11.5, 0, 4]}
                        rotation={[0, 0, 0]}
                        imageTargetVisible={imageTargetVisible}
                      />
                    </RigidBody>

                    <RigidBody gravityScale={1}>
                      <Text
                        name="L"
                        scale={0.75}
                        position={[-8.5, 0, 4]}
                        rotation={[0, 0, 0]}
                        imageTargetVisible={imageTargetVisible}
                      />
                    </RigidBody>

                    <RigidBody gravityScale={1}>
                      <Text
                        name="O"
                        scale={0.75}
                        position={[-7, 0, 4]}
                        rotation={[0, 0, 0]}
                        imageTargetVisible={imageTargetVisible}
                      />
                    </RigidBody>

                    <RigidBody gravityScale={1}>
                      <Text
                        name="R"
                        scale={0.75}
                        position={[-4, 0, 4]}
                        rotation={[0, 0, 0]}
                        imageTargetVisible={imageTargetVisible}
                      />
                    </RigidBody>
                  </group>

                  <group position={[-10, 0, -6]}>
                    <RigidBody gravityScale={1}>
                      <Text
                        name="V"
                        scale={0.75}
                        position={[0, 0, 0]}
                        rotation={[0, 0, 0]}
                        imageTargetVisible={imageTargetVisible}
                      />
                    </RigidBody>

                    <RigidBody gravityScale={1}>
                      <Text
                        name="E"
                        scale={0.75}
                        position={[3, 0, 0]}
                        rotation={[0, 0, 0]}
                        imageTargetVisible={imageTargetVisible}
                      />
                    </RigidBody>

                    <RigidBody gravityScale={1}>
                      <Text
                        name="R"
                        scale={0.75}
                        position={[6, 0, 0]}
                        rotation={[0, 0, 0]}
                        imageTargetVisible={imageTargetVisible}
                      />
                    </RigidBody>

                    <RigidBody gravityScale={1}>
                      <Text
                        name="M"
                        scale={0.75}
                        position={[9, 0, 0]}
                        rotation={[0, 0, 0]}
                        imageTargetVisible={imageTargetVisible}
                      />
                    </RigidBody>

                    <RigidBody gravityScale={1}>
                      <Text
                        name="E"
                        scale={0.75}
                        position={[13, 0, 0]}
                        rotation={[0, 0, 0]}
                        imageTargetVisible={imageTargetVisible}
                      />
                    </RigidBody>

                    <RigidBody gravityScale={1}>
                      <Text
                        name="I"
                        scale={0.75}
                        position={[16, 0, 0]}
                        rotation={[0, 0, 0]}
                        imageTargetVisible={imageTargetVisible}
                      />
                    </RigidBody>
                    <RigidBody gravityScale={1}>
                      <Text
                        name="R"
                        scale={0.75}
                        position={[18, 0, 0]}
                        rotation={[0, 0, 0]}
                        imageTargetVisible={imageTargetVisible}
                      />
                    </RigidBody>
                    <RigidBody gravityScale={1}>
                      <Text
                        name="E"
                        scale={0.75}
                        position={[21, 0, 0]}
                        rotation={[0, 0, 0]}
                        imageTargetVisible={imageTargetVisible}
                      />
                    </RigidBody>
                  </group>

                  <RigidBody type="fixed" name="floor">
                    <Box
                      position={[0, -1, 0]}
                      args={[10000, 2, 10000]}
                      receiveShadow
                    >
                      <shadowMaterial attach="material" opacity={0.7} />
                    </Box>
                  </RigidBody>
                </Physics>
              </group>
            </group>
          </scene>
        </Canvas>
        <canvas
          ref={canvasRef}
          width="480"
          height="640"
          style={{ position: "absolute" }}
        ></canvas>
      </div>
    </>
  );
}
