import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";

export function Balloon1({ color, ...props }) {
  const { nodes, materials } = useGLTF(
    `${import.meta.env.BASE_URL}balloon.glb`
  );

  const material = materials.phong84.clone();
  if (color) {
    material.color = new THREE.Color(color);
    material.transparent = true;
  }

  let random = Math.random();

  // Animate going up take delta into account
  const group = useRef();
  useFrame((state, delta) => {
    if (group.current.position.y < 3) {
      material.opacity = group.current.position.y / 3;
    }

    group.current.position.y += 2 * delta + random / 100;

    if (group.current.position.y > 20) {
      group.current.position.y = -2;
      material.opacity = 0;
      random = Math.random();
    }
  });

  return (
    <group {...props} ref={group} scale={10} dispose={null}>
      <mesh
        castShadow
        geometry={nodes.Cube_050_Cube_060_Material.geometry}
        material={materials.white}
        position={[-1.208, 0, 1.018]}
        rotation={[Math.PI / 2, 0, -0.58]}
        scale={0.005}
      />
      <mesh
        castShadow
        geometry={nodes.Cube_050_Cube_060_silver.geometry}
        material={material}
        position={[-1.208, 0, 1.018]}
        rotation={[Math.PI / 2, 0, -0.58]}
        scale={0.005}
      />
    </group>
  );
}

useGLTF.preload(`${import.meta.env.BASE_URL}balloon.glb`);
