import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import * as THREE from "three";

export function Bear(props) {
  const { nodes, materials } = useGLTF(`${import.meta.env.BASE_URL}bear.glb`);

  materials.fabric_brown.roughness = 0.9;
  materials.fabric_brown.color = new THREE.Color("white");

  return (
    <group {...props} dispose={null}>
      <group
        rotation={[Math.PI / 2, 0, -1.056]}
        position={[0, 1.42, 0]}
        scale={0.05}
      >
        <mesh
          castShadow
          geometry={nodes.Mesh068.geometry}
          material={materials.fabric_brown}
        />
        <mesh
          castShadow
          geometry={nodes.Mesh068_1.geometry}
          material={materials.black}
        />
      </group>
    </group>
  );
}

useGLTF.preload(`${import.meta.env.BASE_URL}bear.glb`);
