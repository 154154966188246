import ReactDOM from "react-dom/client";
import Experience from "./Experience";

const root = ReactDOM.createRoot(document.querySelector("#root"));

root.render(
  <>
    <Experience />

    <div className="vignette"></div>
  </>
);
