import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { gestureDetectorComponent } from "./GestureComponent";
import { useFrame, useThree } from "@react-three/fiber";
import { CuboidCollider, RigidBody, useRapier } from "@react-three/rapier";
import * as THREE from "three";

export function Car({ position, ...props }) {
  const { nodes, materials } = useGLTF(`${import.meta.env.BASE_URL}car.glb`);
  const rigidBodyRef = useRef();
  const car = useRef();

  gestureDetectorComponent.init("body");

  let positionRaw = null;
  let startPositionRaw = null;

  const handleTouch = (e) => {
    positionRaw = e.detail.positionRaw;
    startPositionRaw = startPositionRaw || positionRaw;
  };

  const clearTouch = (e) => {
    startPositionRaw = null;
  };

  window.addEventListener("onefingerstart", handleTouch);
  window.addEventListener("onefingermove", handleTouch);
  window.addEventListener("onefingerend", clearTouch);

  const joystickOrigin = document.querySelector(".js-joystick-origin");
  const joystickPosition = document.querySelector(".js-joystick-position");
  const joystickContainer = document.querySelector(".js-joystick-container");

  const camera = useThree((state) => state.camera);

  useFrame((_, delta) => {
    if (startPositionRaw) {
      joystickContainer.classList.add("visible");

      const isTablet = window.matchMedia("(min-width: 640px)").matches;
      const isDesktop = window.matchMedia("(min-width: 961px)").matches;

      const maxRawDistance =
        Math.min(window.innerWidth, window.innerHeight) /
        (isDesktop ? 18 : isTablet ? 17 : 8);

      let rawOffsetX = positionRaw.x - startPositionRaw.x;
      let rawOffsetY = positionRaw.y - startPositionRaw.y;

      const rawDistance = Math.sqrt(
        Math.pow(rawOffsetX, 2) + Math.pow(rawOffsetY, 2)
      );

      // Normalize to maxRawDistance
      if (rawDistance > maxRawDistance) {
        rawOffsetX *= maxRawDistance / rawDistance;
        rawOffsetY *= maxRawDistance / rawDistance;
      }

      const widthScale = 100 / window.innerWidth;
      const heightScale = 100 / window.innerHeight;

      joystickOrigin.style.left = `${startPositionRaw.x * widthScale}%`;
      joystickOrigin.style.top = `${startPositionRaw.y * heightScale}%`;
      joystickPosition.style.left = `${
        (startPositionRaw.x + rawOffsetX) * widthScale
      }%`;
      joystickPosition.style.top = `${
        (startPositionRaw.y + rawOffsetY) * heightScale
      }%`;

      const offsetX = rawOffsetX / maxRawDistance;
      const offsetY = rawOffsetY / maxRawDistance;

      const forward = -Math.min(Math.max(-1, offsetY), 1);
      const side = -Math.min(Math.max(-1, offsetX), 1);

      let dir;
      const moveZ = -forward * delta;
      const moveX = -side * delta;

      // get y rot of camera
      const camY = camera.rotation.y;

      let joystickRot = Math.atan2(forward, side);

      joystickRot -= camY;

      rigidBodyRef.current.setRotation(
        new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(0, 1, 0),
          -joystickRot
        )
      );

      rigidBodyRef.current.setTranslation({
        x: rigidBodyRef.current.translation().x + moveX,
        y:
          rigidBodyRef.current.translation().y < 0
            ? 0
            : rigidBodyRef.current.translation().y,
        z: rigidBodyRef.current.translation().z + moveZ,
      });
    } else {
      joystickContainer.classList.remove("visible");
    }
  });

  return (
    <RigidBody
      ref={rigidBodyRef}
      rotation={[0, 0, 0]}
      position={position}
      gravityScale={30}
    >
      <mesh
        ref={car}
        scale={25}
        castShadow
        geometry={nodes.pasted__polySurface361.geometry}
        material={materials.pasted__phong15}
      />
    </RigidBody>
  );
}
