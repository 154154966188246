import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import * as THREE from "three";

export function SphereToy(props) {
  const { nodes, materials } = useGLTF(`${import.meta.env.BASE_URL}sphere.glb`);

  materials.phong85.rougness = 1;
  materials.phong85.metalness = 0;

  materials.phong84.color = new THREE.Color("white");
  materials.phong85.color = new THREE.Color("black");
  materials.phong83.color = new THREE.Color("white");
  materials.phong82.color = new THREE.Color("black");
  materials["Cup-base"].color = new THREE.Color("white");

  return (
    <group {...props} scale={20} dispose={null}>
      <mesh
        castShadow
        geometry={nodes.pSphere3.geometry}
        material={materials["Cup-base"]}
        position={[0.136, -0.435, -0.374]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.005}
      />
      <mesh
        castShadow
        geometry={nodes.pTorus4.geometry}
        material={materials.phong84}
        position={[0.136, -0.426, -0.374]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.005}
      />
      <mesh
        castShadow
        geometry={nodes.pTorus5.geometry}
        material={materials.phong85}
        position={[0.136, -0.426, -0.374]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.005}
      />
      <mesh
        castShadow
        geometry={nodes.pTorus6.geometry}
        material={materials.phong83}
        position={[0.136, -0.426, -0.374]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.005}
      />
      <mesh
        castShadow
        geometry={nodes.pTorus7.geometry}
        material={materials.phong82}
        position={[0.136, -0.426, -0.374]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.005}
      />
    </group>
  );
}

useGLTF.preload(`${import.meta.env.BASE_URL}sphere.glb`);
