import React from "react";
import { useGLTF } from "@react-three/drei";
import { RigidBody } from "@react-three/rapier";
import * as THREE from "three";

export function BabyCar(props) {
  const { nodes, materials } = useGLTF(
    `${import.meta.env.BASE_URL}baby-car.glb`
  );

  materials.red.color = new THREE.Color("white");

  return (
    <RigidBody {...props} gravityScale={30} type="fixed" colliders="hull">
      <group {...props} scale={12}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane.geometry}
          material={materials.black}
          position={[0.002, 0.159, -0.028]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane001.geometry}
          material={materials.black}
          position={[-0.105, 0.177, -0.026]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface1001.geometry}
          material={materials.red}
          position={[-0.053, 0.31, -0.027]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <group
          position={[0.065, 0.192, -0.027]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh197.geometry}
            material={materials.metal}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh197_1.geometry}
            material={materials.lambert12}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh197_2.geometry}
            material={materials.red}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface101.geometry}
          material={materials.metal}
          position={[-0.075, 0.091, -0.121]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface102.geometry}
          material={materials.metal}
          position={[-0.09, 0.056, 0.077]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface103.geometry}
          material={materials.metal}
          position={[-0.131, 0.058, 0.077]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface104.geometry}
          material={materials.metal}
          position={[-0.089, 0.057, 0.077]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface105.geometry}
          material={materials.metal}
          position={[-0.129, 0.056, 0.077]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface106.geometry}
          material={materials.metal}
          position={[-0.115, 0.05, 0.077]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface107.geometry}
          material={materials.metal}
          position={[-0.107, 0.049, 0.077]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface108.geometry}
          material={materials.metal}
          position={[-0.128, 0.111, 0.077]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface109.geometry}
          material={materials.metal}
          position={[-0.14, 0.095, 0.077]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface11.geometry}
          material={materials.phong97}
          position={[0.112, 0.061, -0.13]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface110.geometry}
          material={materials.metal}
          position={[-0.134, 0.107, 0.077]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface111.geometry}
          material={materials.metal}
          position={[-0.09, 0.114, 0.077]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface112.geometry}
          material={materials.metal}
          position={[-0.085, 0.108, 0.077]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface113.geometry}
          material={materials.metal}
          position={[-0.143, 0.068, 0.077]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface114.geometry}
          material={materials.metal}
          position={[-0.081, 0.07, 0.077]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface115.geometry}
          material={materials.metal}
          position={[-0.075, 0.071, 0.076]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface116.geometry}
          material={materials.phong97}
          position={[-0.113, 0.084, -0.119]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface117.geometry}
          material={materials.metal}
          position={[-0.111, 0.118, -0.12]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface118.geometry}
          material={materials.metal}
          position={[-0.121, 0.114, -0.12]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface119.geometry}
          material={materials.metal}
          position={[-0.147, 0.071, -0.12]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface12.geometry}
          material={materials.metal}
          position={[0.114, 0.061, -0.129]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface120.geometry}
          material={materials.metal}
          position={[-0.083, 0.099, -0.121]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface121.geometry}
          material={materials.metal}
          position={[-0.148, 0.086, -0.12]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface13.geometry}
          material={materials.metal}
          position={[0.116, 0.085, -0.129]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface14.geometry}
          material={materials.metal}
          position={[0.126, 0.083, -0.129]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface15.geometry}
          material={materials.metal}
          position={[0.129, 0.079, -0.129]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface16.geometry}
          material={materials.metal}
          position={[0.088, 0.049, -0.128]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface17.geometry}
          material={materials.metal}
          position={[0.089, 0.069, -0.128]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface18.geometry}
          material={materials.metal}
          position={[0.094, 0.078, -0.128]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface19.geometry}
          material={materials.metal}
          position={[0.098, 0.081, -0.128]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface20.geometry}
          material={materials.metal}
          position={[0.108, 0.083, -0.129]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface21.geometry}
          material={materials.metal}
          position={[0.135, 0.069, -0.129]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface22.geometry}
          material={materials.metal}
          position={[0.086, 0.054, -0.128]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface23.geometry}
          material={materials.metal}
          position={[0.086, 0.065, -0.128]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface24.geometry}
          material={materials.metal}
          position={[0.098, 0.041, -0.128]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface25.geometry}
          material={materials.metal}
          position={[0.108, 0.037, -0.129]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface26.geometry}
          material={materials.metal}
          position={[0.113, 0.035, -0.129]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface27.geometry}
          material={materials.metal}
          position={[0.137, 0.052, -0.129]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface28.geometry}
          material={materials.metal}
          position={[0.14, 0.063, -0.129]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface29.geometry}
          material={materials.metal}
          position={[0.133, 0.051, -0.129]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface30.geometry}
          material={materials.metal}
          position={[0.127, 0.042, -0.129]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface31.geometry}
          material={materials.metal}
          position={[0.126, 0.041, -0.129]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface32.geometry}
          material={materials.metal}
          position={[0.096, 0.042, -0.128]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface55.geometry}
          material={materials.phong97}
          position={[0.115, 0.061, 0.073]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface56.geometry}
          material={materials.metal}
          position={[0.115, 0.06, 0.009]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface57.geometry}
          material={materials.metal}
          position={[0.119, 0.085, 0.073]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface58.geometry}
          material={materials.metal}
          position={[0.129, 0.083, 0.073]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface59.geometry}
          material={materials.metal}
          position={[0.132, 0.079, 0.073]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface60.geometry}
          material={materials.metal}
          position={[0.09, 0.049, 0.074]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface61.geometry}
          material={materials.metal}
          position={[0.092, 0.069, 0.074]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface62.geometry}
          material={materials.metal}
          position={[0.097, 0.078, 0.074]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface63.geometry}
          material={materials.metal}
          position={[0.101, 0.081, 0.074]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface64.geometry}
          material={materials.metal}
          position={[0.111, 0.083, 0.073]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface65.geometry}
          material={materials.metal}
          position={[0.138, 0.069, 0.073]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface66.geometry}
          material={materials.metal}
          position={[0.089, 0.054, 0.074]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface67.geometry}
          material={materials.metal}
          position={[0.089, 0.065, 0.074]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface68.geometry}
          material={materials.metal}
          position={[0.101, 0.041, 0.074]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface69.geometry}
          material={materials.metal}
          position={[0.111, 0.037, 0.073]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface70.geometry}
          material={materials.metal}
          position={[0.116, 0.035, 0.073]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface71.geometry}
          material={materials.metal}
          position={[0.14, 0.052, 0.073]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface72.geometry}
          material={materials.metal}
          position={[0.143, 0.063, 0.073]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface73.geometry}
          material={materials.metal}
          position={[0.136, 0.051, 0.073]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface74.geometry}
          material={materials.metal}
          position={[0.129, 0.042, 0.073]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface75001.geometry}
          material={materials.metal}
          position={[0.129, 0.041, 0.073]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface76.geometry}
          material={materials.metal}
          position={[0.099, 0.042, 0.074]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface77.geometry}
          material={materials.phong97}
          position={[-0.11, 0.084, 0.076]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface78.geometry}
          material={materials.metal}
          position={[-0.111, 0.084, -0.018]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface79.geometry}
          material={materials.metal}
          position={[-0.104, 0.117, 0.077]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface8.geometry}
          material={materials.red}
          position={[-0.008, 0.189, -0.032]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface80.geometry}
          material={materials.metal}
          position={[-0.097, 0.116, -0.12]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface81.geometry}
          material={materials.metal}
          position={[-0.091, 0.111, -0.12]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface82.geometry}
          material={materials.metal}
          position={[-0.144, 0.065, -0.12]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface83.geometry}
          material={materials.metal}
          position={[-0.145, 0.092, -0.12]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface84.geometry}
          material={materials.metal}
          position={[-0.139, 0.105, -0.12]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface85.geometry}
          material={materials.metal}
          position={[-0.134, 0.109, -0.12]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface86.geometry}
          material={materials.metal}
          position={[-0.114, 0.114, 0.077]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface87.geometry}
          material={materials.metal}
          position={[-0.078, 0.095, 0.076]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface88.geometry}
          material={materials.metal}
          position={[-0.145, 0.074, 0.077]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface89.geometry}
          material={materials.metal}
          position={[-0.144, 0.089, 0.077]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface90.geometry}
          material={materials.metal}
          position={[-0.129, 0.055, -0.12]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface91.geometry}
          material={materials.metal}
          position={[-0.115, 0.05, -0.12]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface92.geometry}
          material={materials.metal}
          position={[-0.107, 0.05, -0.12]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface93.geometry}
          material={materials.metal}
          position={[-0.078, 0.075, -0.121]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface94.geometry}
          material={materials.metal}
          position={[-0.071, 0.087, 0.076]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface95.geometry}
          material={materials.metal}
          position={[-0.083, 0.073, -0.121]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface96.geometry}
          material={materials.metal}
          position={[-0.09, 0.06, -0.12]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface97.geometry}
          material={materials.metal}
          position={[-0.091, 0.059, -0.12]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.polySurface98.geometry}
          material={materials.metal}
          position={[-0.132, 0.057, -0.12]}
          rotation={[Math.PI / 2, 0, -1.585]}
          scale={0.005}
        />
      </group>
    </RigidBody>
  );
}

useGLTF.preload(`${import.meta.env.BASE_URL}baby-car.glb`);
