import { extend, useFrame } from "@react-three/fiber";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import roboto from "./Roboto-Condensed_Regular.json";
import * as THREE from "three";
import { useRef } from "react";

extend({ TextGeometry });

export function Text({
  name,
  color = "#f7941d",
  imageTargetVisible,
  ...props
}) {
  const font = new FontLoader().parse(roboto);
  const config = {
    font,
    size: 5,
    height: 1,
    curveSegments: 4,
    evelEnabled: false,
  };

  const material = new THREE.MeshPhongMaterial({
    color: new THREE.Color(color),
    transparent: true,
    opacity: 0,
  });

  const mesh = useRef();

  let elapsedTime = 0;
  useFrame((state, delta) => {
    if (!imageTargetVisible) {
      return;
    }

    elapsedTime += delta;

    if (material.opacity < 1 && elapsedTime > 1.5) {
      material.opacity += delta * 2;
      material.needsUpdate = true;
    }
  });

  return (
    <mesh {...props} ref={mesh} material={material} castShadow>
      <textGeometry args={[name, config]} />
    </mesh>
  );
}
