import React from "react";
import { useGLTF } from "@react-three/drei";
import * as THREE from "three";

export function Billboard(props) {
  const { nodes, materials } = useGLTF(
    `${import.meta.env.BASE_URL}billboard.glb`
  );

  const texture = new THREE.TextureLoader().load(
    `${import.meta.env.BASE_URL}foto.jpg`
  );
  texture.colorSpace = THREE.SRGBColorSpace;

  const material = new THREE.MeshBasicMaterial({
    map: texture,
    toneMapped: false,
  });

  const material2 = materials["02_-_Default"];
  material2.color = new THREE.Color("#f7941d");

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        geometry={nodes["Frame_16-9_02_-_Default_0"].geometry}
        material={materials["02_-_Default"]}
        position={[0, 7.8, 0]}
      />
      <mesh
        castShadow
        geometry={nodes["Board_16-9_Board_0"].geometry}
        material={material}
        position={[0, 7.8, 0]}
      />
    </group>
  );
}

useGLTF.preload(`${import.meta.env.BASE_URL}billboard.glb`);
