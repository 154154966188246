type FingerMoveEvent = {
  positionChange: {
    x: number;
    y: number;
  };
  touchCount: number;
  startTime: number;
  startSpread: number;
  spreadChange: number;
  startPosition: {
    x: number;
    y: number;
  };
  positionRaw: {
    x: number;
    y: number;
  };
};

interface GestureDetectorComponentDefinition {
  init: (element: string) => void;
  remove: () => void;
  emitGestureEvent: (event: TouchEvent) => void;
  getTouchState: (event: TouchEvent) => FingerMoveEvent;
  getEventPrefix: (event: number) => string;
}

// Component that detects and emits events for touch gestures
const gestureDetectorComponent: GestureDetectorComponentDefinition = {
  init(element) {
    this.targetElement = element && document.querySelector(element);
    this.el = window;

    if (!this.targetElement) {
      this.targetElement = this.el;
    }

    this.internalState = {
      previousState: null,
    };

    this.emitGestureEvent = this.emitGestureEvent.bind(this);

    this.targetElement.addEventListener("touchstart", this.emitGestureEvent);
    this.targetElement.addEventListener("touchend", this.emitGestureEvent);
    this.targetElement.addEventListener("touchmove", this.emitGestureEvent);
  },
  remove() {
    this.targetElement.removeEventListener("touchstart", this.emitGestureEvent);
    this.targetElement.removeEventListener("touchend", this.emitGestureEvent);
    this.targetElement.removeEventListener("touchmove", this.emitGestureEvent);
  },
  emitGestureEvent(event) {
    const currentState = this.getTouchState(event);
    const { previousState } = this.internalState;

    const gestureContinues =
      previousState &&
      currentState &&
      currentState.touchCount == previousState.touchCount;

    const gestureEnded = previousState && !gestureContinues;
    const gestureStarted = currentState && !gestureContinues;

    if (gestureEnded) {
      const eventName = `${this.getEventPrefix(
        previousState.touchCount
      )}fingerend`;

      const event = new CustomEvent(eventName, { detail: previousState });
      this.el.dispatchEvent(event);
      this.internalState.previousState = null;
    }

    if (gestureStarted) {
      currentState.startTime = performance.now();
      currentState.startPosition = currentState.position;
      currentState.startSpread = currentState.spread;
      const eventName = `${this.getEventPrefix(
        currentState.touchCount
      )}fingerstart`;

      const event = new CustomEvent(eventName, { detail: currentState });
      this.el.dispatchEvent(event);
      this.internalState.previousState = currentState;
    }

    if (gestureContinues) {
      const eventDetail: any = {
        positionChange: {
          x: currentState.position.x - previousState.position.x,
          y: currentState.position.y - previousState.position.y,
        },
      };

      if (currentState.spread) {
        eventDetail.spreadChange = currentState.spread - previousState.spread;
      }

      // Update state with new data
      Object.assign(previousState, currentState);

      // Add state data to event detail
      Object.assign(eventDetail, previousState);

      const eventName = `${this.getEventPrefix(
        currentState.touchCount
      )}fingermove`;

      const event = new CustomEvent(eventName, { detail: eventDetail });
      this.el.dispatchEvent(event);
    }
  },
  getTouchState(event) {
    if (event.touches.length == 0) {
      return null;
    }

    // Convert event.touches to an array so we can use reduce
    const touchList = [];
    for (let i = 0; i < event.touches.length; i++) {
      touchList.push(event.touches[i]);
    }

    const touchState: any = {
      touchCount: touchList.length,
    };

    // Calculate center of all current touches
    const centerPositionRawX =
      touchList.reduce((sum, touch) => sum + touch.clientX, 0) /
      touchList.length;
    const centerPositionRawY =
      touchList.reduce((sum, touch) => sum + touch.clientY, 0) /
      touchList.length;

    touchState.positionRaw = { x: centerPositionRawX, y: centerPositionRawY };

    // Scale touch position and spread by average of window dimensions
    const screenScale = 2 / (window.innerWidth + window.innerHeight);

    touchState.position = {
      x: centerPositionRawX * screenScale,
      y: centerPositionRawY * screenScale,
    };

    // Calculate average spread of touches from the center point
    if (touchList.length >= 2) {
      const spread =
        touchList.reduce(
          (sum, touch) =>
            sum +
            Math.sqrt(
              Math.pow(centerPositionRawX - touch.clientX, 2) +
                Math.pow(centerPositionRawY - touch.clientY, 2)
            ),
          0
        ) / touchList.length;

      touchState.spread = spread * screenScale;
    }

    return touchState;
  },
  getEventPrefix(touchCount) {
    const numberNames = ["one", "two", "three", "many"];
    return numberNames[Math.min(touchCount, 4) - 1];
  },
};

type DragCallback = ({ detail }: { detail: FingerMoveEvent }) => void;

interface FingerMoveComponentDefinition {
  handleEvent: DragCallback;
}

export { gestureDetectorComponent };
