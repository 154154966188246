const imageTargetPipelineModule = (
  appScene,
  groupScene,
  setImageTargetVisible
) => {
  const showTarget = ({ detail }) => {
    appScene.current.position.copy(detail.position);
    appScene.current.quaternion.copy(detail.rotation);
    appScene.current.scale.set(detail.scale, detail.scale, detail.scale);
    appScene.current.visible = true;

    groupScene.current.rotation.x = Math.PI / 2;

    setImageTargetVisible(true);
  };

  const hideTarget = ({ detail }) => {
    // Do nothing
  };

  return {
    // Camera pipeline modules need a name. It can be whatever you want but must be
    // unique within your app.
    name: "image-target-pipeline-module",

    // Listeners are called right after the processing stage that fired them. This guarantees that
    // updates can be applied at an appropriate synchronized point in the rendering cycle.
    listeners: [
      { event: "reality.imagefound", process: showTarget },
      { event: "reality.imageupdated", process: showTarget },
      { event: "reality.imagelost", process: hideTarget },
    ],
  };
};

export { imageTargetPipelineModule };
